import React from 'react';
import { Grid } from '@mui/material';

import { Mui } from '~constants/mui';

import { CreatorsLevelChangeOverviewTable } from '~components/organism/CreatorsLevelChangeOverviewTable';
import { CreatorSummaryTable } from '~components/organism/CreatorSummaryTable';
import { CreditNotesWithProcessingErrorTable } from '~components/organism/CreditNotesWithProcessingErrorTable';
import { DashboardAggregations } from '~components/organism/DashboardAggregations';
import { DashboardPayoutSummary } from '~components/organism/DashboardPayoutSummary';
import { RequireFeature } from '~components/utils/RequireFeature';

import style from './Dashboard.module.scss';

export const Dashboard: React.FC = () => {
    return (
        <div className={ style.root } data-test="dashboardPage">
            <Grid
                container
                spacing={ Mui.gridSpacing }
                alignItems="stretch">
                <Grid item xs={ 12 }>
                    <DashboardAggregations />
                </Grid>
                <Grid item xs={ 6 }>
                    <CreatorSummaryTable />
                </Grid>
                <Grid item container xs={ 6 } spacing={ Mui.gridSpacing }>
                    <RequireFeature feature="cash_payout_via_fico">
                        <Grid item xs={ 12 }>
                            <CreditNotesWithProcessingErrorTable />
                        </Grid>
                    </RequireFeature>
                    <Grid item xs={ 12 }>
                        <DashboardPayoutSummary />
                    </Grid>
                    <Grid item xs={ 12 }>
                        <CreatorsLevelChangeOverviewTable />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
