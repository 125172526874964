import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { PayoutCycle } from '~api/model/payout-cycle';

import { formatDate, message } from '~helper/_common';

import { Headline } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';

export type PayoutCyclesTableProps = {
    className?: string;
    headline?: string;
    loading?: boolean;
    payoutCycles: PayoutCycle[] | null;
};

export const PayoutCyclesTable: React.FC<PayoutCyclesTableProps> = ({
    className,
    headline,
    loading,
    payoutCycles,
}) => {
    return (
        <>
            { headline && <Headline variant="h4" data-test="payoutCyclesTable.headline">{ headline }</Headline> }
            <TableContainer className={ className } component={ Paper } data-test="payoutCyclesTable">
                <Table>
                    <TableHead>
                        <TableCell>{ message('payoutCycles.labels.name') }</TableCell>
                        <TableCell>{ message('payoutCycles.history.date') }</TableCell>
                    </TableHead>
                    { loading && (
                        <TableRow data-test="payoutCyclesTable.loading">
                            <TableCell data-test="payoutCyclesTable.loading.name">
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell data-test="payoutCyclesTable.loading.nextPayoutDate">
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                    ) }
                    <TableBody>
                        { payoutCycles?.map((payoutCycle) => (
                            <TableRow key={ payoutCycle.payoutCycleId } data-test="payoutCyclesTable.entry">
                                <TableCell data-test="payoutCyclesTable.entry.name">
                                    { payoutCycle.payoutCycleName }
                                </TableCell>
                                <TableCell data-test="payoutCyclesTable.entry.nextPayoutDate">
                                    { formatDate(payoutCycle.nextPayoutDate) }
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
