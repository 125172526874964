import { Dashboard } from '~components/pages/Dashboard';
import { Authentication } from '~components/templates/Authentication';
import { Layout } from '~components/templates/Layout';

const HomeRoute = (): JSX.Element => {
    return (
        <Authentication>
            <Layout>
                <Dashboard />
            </Layout>
        </Authentication>
    );
};

// Disable next.js Automatic Static Optimization to get runtime configuration for this page
HomeRoute.getInitialProps = () => {
    return {};
};

export default HomeRoute;
