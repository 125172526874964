import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';

import { formatDate, message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { fetchCreatorsLevelChangeOverview, useCreatorsLevelChangeOverview } from '~modules/creatorsLevelChangeOverview';

import { Headline } from '~components/atoms/Headline';
import { LevelBadge } from '~components/atoms/LevelBadge';
import { Paper } from '~components/atoms/Paper';
import style from '~components/organism/CreatorsLevelChangeOverviewTable/CreatorsLevelChangeOverviewTable.module.scss';
import { ComponentCommonProps } from '~components/types';

export type CreatorsLevelChangeOverviewTableProps = ComponentCommonProps;

export const CreatorsLevelChangeOverviewTable: React.FC<CreatorsLevelChangeOverviewTableProps> = ({
    className,
    'data-test': dataTest = 'creatorsLevelChangeOverviewTable',
}) => {
    const dispatch = useDispatch();

    useInitialLoad(() => {
        dispatch(fetchCreatorsLevelChangeOverview());
    });

    const { levelChangeOverviews, fetchState } = useCreatorsLevelChangeOverview();

    const sortedLevelChangeOverviews = (levelChangeOverviews || [])
        .map(overview => overview)
        .sort((overview1, overview2) => {
            return (overview2.name < overview1.name ? 1 : -1);
        });

    const loading = fetchState === 'FETCHING' || fetchState === 'INIT';

    if (fetchState === 'ERROR') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.errorNotification` }>
                { message('api.error') }
            </Alert>
        );
    }

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.forbiddenNotification` }>
                { message('api.forbidden') }
            </Alert>
        );
    }

    return (
        <Paper
            className={ clsx(style.root, className) }
            data-test={ `${dataTest}.table` }>
            <Headline
                variant="h4"
                loading={ loading }
                data-test={ `${dataTest}.headline` }>
                { message('creator.level.overview.table.headline') }
            </Headline>
            <Table>
                <TableHead>
                    <TableRow data-test={ `${dataTest}.table.header` }>
                        <TableCell data-test={ `${dataTest}.table.header.name` }>
                            { message('creator.level.overview.table.nickname') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.previousLevel` }>
                            { message('creator.level.overview.table.previousLevel') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.currentLevel` }>
                            { message('creator.level.overview.table.currentLevel') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.date` }>
                            { message('creator.level.overview.table.date') }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { fetchState === 'SUCCESS' && sortedLevelChangeOverviews?.map(content => (
                        content.creatorLevelChangeDetails.map(details => (
                            <TableRow
                                key={ `${content.name}${details.date}` }
                                data-test={ `${dataTest}.table.content` }>
                                <TableCell data-test={ `${dataTest}.table.content.name` }>
                                    { content.name }
                                </TableCell>
                                <TableCell data-test={ `${dataTest}.table.details.previousLevel` }>
                                    <LevelBadge level={ details.previousLevel } className={ style.badgeForName } data-test={ `${dataTest}.table.content.previous.levelBadge` } />
                                    { message(`creator.level.${details.previousLevel}`) }
                                </TableCell>
                                <TableCell data-test={ `${dataTest}.table.details.currentLevel` }>
                                    <LevelBadge level={ details.currentLevel } className={ style.badgeForName } data-test={ `${dataTest}.table.content.current.levelBadge` } />
                                    { message(`creator.level.${details.currentLevel}`) }
                                </TableCell>
                                <TableCell data-test={ `${dataTest}.table.details.date` }>{ formatDate(details.date) }</TableCell>
                            </TableRow>
                        )))) }
                    { fetchState === 'SUCCESS' && !sortedLevelChangeOverviews.length && (
                        <TableRow
                            data-test={ `${dataTest}.table.content.empty` }>
                            <TableCell
                                colSpan={ 4 }
                                data-test={ `${dataTest}.table.content.empty.label` }
                                align="center">
                                { message('creator.level.overview.table.noChanges') }
                            </TableCell>
                        </TableRow>
                    ) }
                    { (fetchState === 'INIT' || fetchState === 'FETCHING') && [ ...Array(4).keys() ].map(index => (
                        <TableRow
                            key={ index }
                            data-test={ `${dataTest}.table.loading` }>
                            <TableCell data-test={ `${dataTest}.table.loading.name` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.previousLevel` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.currentLevel` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.date` }>
                                <Skeleton />
                            </TableCell>
                        </TableRow>
                    )) }
                </TableBody>
            </Table>
        </Paper>
    );
};
