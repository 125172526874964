import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';

import { Routes } from '~constants/routes';

import { message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { resetAllCreatorFilters } from '~modules/creatorFilter';
import { fetchCreators, useCreators } from '~modules/creators';

import { Button } from '~components/atoms/Button';
import { Headline } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';
import { CreatorTable } from '~components/organism/CreatorTable';
import { ComponentCommonProps } from '~components/types';

import style from './CreatorSummaryTable.module.scss';

export type CreatorSummaryTableProps = ComponentCommonProps;

export const CreatorSummaryTable: React.FC<CreatorSummaryTableProps> = ({
    className,
    'data-test': dataTest = 'creatorSummaryTable',
}) => {
    const dispatch = useDispatch();
    const { creators, fetchState } = useCreators();
    const sortedCreators = (creators || [])
        .map(creator => creator)
        .sort((creator1, creator2) => {
            const totalViews1 = creator1.billableLiveViewCount + creator1.billableReplayViewCount;
            const totalViews2 = creator2.billableLiveViewCount + creator2.billableReplayViewCount;

            return totalViews2 - totalViews1;
        });
    const loading = fetchState === 'FETCHING' || fetchState === 'INIT';

    useInitialLoad(() => {
        dispatch(fetchCreators());
        dispatch(resetAllCreatorFilters('unknown', null));
    });

    if (fetchState === 'ERROR') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.errorNotification` }>
                { message('api.error') }
            </Alert>
        );
    }

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.forbiddenNotification` }>
                { message('api.forbidden') }
            </Alert>
        );
    }

    return (
        <Paper
            className={ clsx(style.root, className) }
            data-test={ dataTest }>
            <Headline
                variant="h4"
                loading={ loading }
                data-test={ `${dataTest}.headline` }>
                { message('creatorSummary.headline') }
            </Headline>
            <CreatorTable
                creators={ sortedCreators }
                visibleFields={ [ 'gender', 'nicknameWithLevelBadge', 'age', 'city', 'totalViews' ] }
                creatorSource="unknown"
                linkToCreator
                showPagination={ false }
                showFilterAndSort={ false }
                loading={ loading }
                data-test={ `${dataTest}.creators` } />
            <Link href={ Routes.CREATORS }>
                <Button
                    variant="text"
                    data-test={ `${dataTest}.showAllCreators` }>
                    { message('creatorSummary.showAll') }
                </Button>
            </Link>
        </Paper>
    );
};
