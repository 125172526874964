import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CreatorAggregations, CreatorsDashboardTileDetail } from '~api/model/dashboardAggregations';

import { chartColors } from '~constants/colors';
import { CreatorLevel } from '~constants/creatorLevel';

import { formatDate, formatNumber, message } from '~helper/_common';

import { fetchCreatorDashboardDetails, useCreatorDashboardDetails } from '~modules/creatorDashboardDetails';

import { Text } from '~components/atoms/Text';
import { chartSelectionProps, ChartTypeSelectionProps, DetailedChart, RenderChart, renderChartTypeSelection, WithOriginalValues } from '~components/organism/DetailedChart';
import { ComponentCommonProps } from '~components/types';

import style from './CreatorsDetailsTile.module.scss';

export type CreatorsDetailsTileProps = ComponentCommonProps & {
    data: CreatorAggregations['creators'] | null;
};

const getTooltipLabel = (type: string): string => {
    switch (type) {
        case 'totalMember':
        case 'deltaMember':
            return message('creator.level.MEMBER');
        case 'totalSilver':
        case 'deltaSilver':
            return message('creator.level.SILVER');
        case 'totalGold':
        case 'deltaGold':
            return message('creator.level.GOLD');
        case 'totalPlatinum':
        case 'deltaPlatinum':
            return message('creator.level.PLATINUM');
        default:
            return message('common.total');
    }
};

export const CreatorsDetailsTile: React.FC<CreatorsDetailsTileProps> = ({
    data,
    'data-test': dataTest = 'creatorsDetailsTile'
}) => {
    const dashboardDetails = useCreatorDashboardDetails();
    const chartData = dashboardDetails.dashboardDetails?.details || [];
    const chartProps: ChartTypeSelectionProps = useMemo(() => ({
        [CreatorLevel.MEMBER]: {
            disabled: !chartData.some(value => value.totalMember),
            label: message('creator.level.MEMBER'),
        },
        [CreatorLevel.SILVER]: {
            disabled: !chartData.some(value => value.totalSilver),
            label: message('creator.level.SILVER'),
        },
        [CreatorLevel.GOLD]: {
            disabled: !chartData.some(value => value.totalGold),
            label: message('creator.level.GOLD'),
        },
        [CreatorLevel.PLATINUM]: {
            disabled: !chartData.some(value => value.totalPlatinum),
            label: message('creator.level.PLATINUM'),
        },
    }), [ chartData ]);
    const renderChart = useCallback<RenderChart>((chartState, dataTest) => {
        const { MEMBER, SILVER, GOLD, PLATINUM, total } = chartSelectionProps(chartState, chartProps);
        const noChartDataSelected = !chartState[CreatorLevel.MEMBER] &&
                !chartState[CreatorLevel.SILVER] &&
                !chartState[CreatorLevel.GOLD] &&
                !chartState[CreatorLevel.PLATINUM];

        return (
            <div
                className={ style.chartArea }
                data-test={ `${dataTest}.mainChart.${chartState.data}` }>
                { chartState.data === 'total' && (
                    <ResponsiveContainer>
                        <AreaChart data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <defs>
                                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.creator } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.creator } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <defs>
                                <linearGradient id="colorMember" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.member } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.member } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <defs>
                                <linearGradient id="colorSilver" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.silver } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.silver } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <defs>
                                <linearGradient id="colorGold" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.gold } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.gold } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <defs>
                                <linearGradient id="colorPlatinum" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.platinum } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.platinum } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            { total?.checked && (
                                <Area
                                    dataKey="total"
                                    type="monotone"
                                    stroke={ chartColors.creator }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill="url(#colorTotal)"
                                    data-test={ `${dataTest}.areaChart.total` } />
                            ) }
                            { MEMBER?.checked && (
                                <Area
                                    dataKey="totalMember"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.member }
                                    fillOpacity={ 1 }
                                    fill="url(#colorMember)"
                                    data-test={ `${dataTest}.areaChart.member` } />
                            ) }
                            { SILVER?.checked && (
                                <Area
                                    dataKey="totalSilver"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.silver }
                                    fillOpacity={ 1 }
                                    fill="url(#colorSilver)"
                                    data-test={ `${dataTest}.areaChart.silver` } />
                            ) }
                            { GOLD?.checked && (
                                <Area
                                    dataKey="totalGold"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.gold }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGold)"
                                    data-test={ `${dataTest}.areaChart.gold` } />
                            ) }
                            { PLATINUM?.checked && (
                                <Area
                                    dataKey="totalPlatinum"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.platinum }
                                    fillOpacity={ 1 }
                                    fill="url(#colorPlatinum)"
                                    data-test={ `${dataTest}.areaChart.platinum` } />
                            ) }
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof CreatorsDashboardTileDetail, data: { payload: WithOriginalValues<CreatorsDashboardTileDetail> }) => (
                                    [
                                        formatNumber(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                        </AreaChart>
                    </ResponsiveContainer>
                ) }
                { chartState.data === 'delta' && (
                    <ResponsiveContainer>
                        <BarChart
                            data={ chartData }
                            margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) }
                                allowDecimals={ false } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: string) => [ formatNumber(value), getTooltipLabel(name) ] }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                            { total?.checked && (
                                <Bar
                                    dataKey="delta"
                                    type="monotone"
                                    stroke={ chartColors.creator }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.creator }
                                    data-test={ `${dataTest}.barChart.total` } />
                            ) }
                            { MEMBER?.checked && (
                                <Bar
                                    dataKey="deltaMember"
                                    type="monotone"
                                    stroke={ chartColors.member }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.member }
                                    data-test={ `${dataTest}.barChart.member` } />
                            ) }
                            { SILVER?.checked && (
                                <Bar
                                    dataKey="deltaSilver"
                                    type="monotone"
                                    stroke={ chartColors.silver }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.silver }
                                    data-test={ `${dataTest}.barChart.silver` } />
                            ) }
                            { GOLD?.checked && (
                                <Bar
                                    dataKey="deltaGold"
                                    type="monotone"
                                    stroke={ chartColors.gold }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.gold }
                                    data-test={ `${dataTest}.barChart.gold` } />
                            ) }
                            { PLATINUM?.checked && (
                                <Bar
                                    dataKey="deltaPlatinum"
                                    type="monotone"
                                    stroke={ chartColors.platinum }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.platinum }
                                    data-test={ `${dataTest}.barChart.platinum` } />
                            ) }
                        </BarChart>
                    </ResponsiveContainer>
                ) }
            </div>
        );
    }, [ dashboardDetails ]);

    return (
        <DetailedChart
            dataType="creator"
            headline={ message('dataTile.creator.headline') }
            label={ message('common.total') }
            value={ formatNumber(data?.total || 0) }
            fetchChartData={ fetchCreatorDashboardDetails }
            fetchState={ dashboardDetails.fetchState }
            hasData={ Boolean(chartData.length) }
            renderChartTypeSelection={ renderChartTypeSelection(chartProps) }
            renderChart={ renderChart }
            data-test={ dataTest } />
    );
};
