import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Grid } from '@mui/material';
import Link from 'next/link';

import { Mui } from '~constants/mui';
import { Routes } from '~constants/routes';

import { message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { fetchDashboardPayoutSummary, useDashboardPayoutSummary } from '~modules/dashboardPayoutSummary';

import { Button } from '~components/atoms/Button';
import { Headline } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';
import { Messages } from '~components/molecules/Messages';
import { PayoutCycleRunTiles } from '~components/organism/PayoutCycleRunTiles';
import { PayoutCyclesTable } from '~components/organism/PayoutCyclesTable';
import { ComponentCommonProps } from '~components/types';

import style from './DashboardPayoutSummary.module.scss';

export const DashboardPayoutSummary: React.FC<ComponentCommonProps> = ({
    'data-test': dataTest = 'dashboardPayoutSummary',
}) => {
    const dispatch = useDispatch();

    useInitialLoad(() => {
        dispatch(fetchDashboardPayoutSummary());
    });

    const {
        isEstimation,
        nextPayoutCycle,
        nextPayoutCycleRun,
        upcomingPayoutCycles,
        fetchState,
    } = useDashboardPayoutSummary();

    const isLoading = fetchState === 'FETCHING' || fetchState === 'INIT';

    if (fetchState === 'ERROR') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.errorNotification` }>
                { message('api.error') }
            </Alert>
        );
    }

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.forbiddenNotification` }>
                { message('api.forbidden') }
            </Alert>
        );
    }

    return (
        <Paper className={ style.root } data-test="dashboardPayoutSummary">
            <Grid container spacing={ Mui.gridSpacing }>
                <Grid item xs={ 12 }>
                    <Messages position="dashboardPayoutSummary" />
                </Grid>
                <Grid item xs={ 12 }>
                    <Headline variant="h4">{ message('payout.runs') }</Headline>
                    <PayoutCycleRunTiles
                        estimated={ isEstimation }
                        loading={ isLoading }
                        payoutCycle={ nextPayoutCycle }
                        payoutCycleRun={ nextPayoutCycleRun }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    { nextPayoutCycleRun?.payoutCycleRunId && (
                        <Link href={ `${Routes.PAYOUT_CYCLES_RUN_DETAILS}${nextPayoutCycleRun.payoutCycleRunId}` }>
                            <Button
                                variant="text"
                                data-test="dashboardPayoutSummary.payoutCycleRunDetailsLink">
                                { message('payoutCycles.details') }
                            </Button>
                        </Link>
                    ) }
                </Grid>
                <Grid item xs={ 12 }>
                    { (isLoading || Boolean(upcomingPayoutCycles?.length)) && (
                        <>
                            <PayoutCyclesTable
                                className={ style.root__upcomingPayouts }
                                headline={ message('payoutCycles.upcomingPayouts') }
                                loading={ isLoading }
                                payoutCycles={ upcomingPayoutCycles }
                                data-test="dashboardPayoutSummary.upcomingPayouts"
                            />
                            <Link href={ Routes.PAYOUT_CYCLES_OVERVIEW }>
                                <Button
                                    variant="text"
                                    data-test="dashboardPayoutSummary.showAllPayoutCycles">
                                    { message('payoutCycles.showAll') }
                                </Button>
                            </Link>
                        </>
                    ) }
                </Grid>
            </Grid>
        </Paper>
    );
};
