import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';

import { CreditNote } from '~api/model/creditNote';

import { formatDate, message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';
import { useVisitCreatorProfile } from '~hooks/useVisitCreatorProfile';

import { fetchCreditNotesWithProcessingError, useCreditNotesWithProcessingError } from '~modules/creditNotesWithProcessingError';

import { Headline } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';
import { ComponentCommonProps } from '~components/types';

import style from './CreditNotesWithProcessingErrorTable.module.scss';

export type CreditNotesWithProcessingErrorTableProps = ComponentCommonProps;

export const CreditNotesWithProcessingErrorTable: React.FC<CreditNotesWithProcessingErrorTableProps> = ({
    className,
    'data-test': dataTest = 'creditNotesWithProcessingErrorTable',
}) => {
    const dispatch = useDispatch();
    const visitCreatorProfile = useVisitCreatorProfile();

    useInitialLoad(() => {
        dispatch(fetchCreditNotesWithProcessingError());
    });

    const { creditNotesWithProcessingError, fetchState } = useCreditNotesWithProcessingError();

    const sortedCreditNotesWithProcessingError = (creditNotesWithProcessingError || [])
        .map(creditNote => creditNote)
        .sort((creditNote1, creditNote2) => {
            return (creditNote2.creditNoteNumber < creditNote1.creditNoteNumber ? 1 : -1);
        });

    const handleCreatorRowClick = (creditNote: CreditNote) => (event: React.MouseEvent<HTMLElement>) => {
        visitCreatorProfile(creditNote.creatorId, event);
    };

    const loading = fetchState === 'FETCHING' || fetchState === 'INIT';

    if (fetchState === 'ERROR') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.errorNotification` }>
                { message('api.error') }
            </Alert>
        );
    }

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.forbiddenNotification` }>
                { message('api.forbidden') }
            </Alert>
        );
    }

    return (
        <Paper
            className={ clsx(style.root, className) }
            data-test={ `${dataTest}.table` }>
            <Headline variant="h4"
                data-test={ `${dataTest}.headline` }
                loading={ loading }>
                { message('creditNotesWithProcessingErrorTable.headline') }
            </Headline>
            <Table>
                <TableHead>
                    <TableRow data-test={ `${dataTest}.table.header` }>
                        <TableCell data-test={ `${dataTest}.table.header.creditNoteNumber` }>
                            { message('creditNote.creditNoteNumber') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.recipient` }>
                            { message('creditNote.recipient') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.processingError.code` }>
                            { message('creditNote.processingError.code') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.processingError.message` }>
                            { message('creditNote.processingError.message') }
                        </TableCell>
                        <TableCell data-test={ `${dataTest}.table.header.documentDate` }>
                            { message('creditNote.documentDate') }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { fetchState === 'SUCCESS' && sortedCreditNotesWithProcessingError?.map(creditNote => (
                        <TableRow
                            onClick={ handleCreatorRowClick(creditNote) }
                            key={ creditNote.creditNoteNumber }
                            data-test={ `${dataTest}.table.creditNote` }>
                            <TableCell data-test={ `${dataTest}.table.creditNote.creditNoteNumber` }>
                                { creditNote.creditNoteNumber }
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.creditNote.recipient` }>
                                { creditNote.recipient }
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.creditNote.processingErrorCode` }>
                                { message(`creditNote.processingError.code.${creditNote.processingErrorCode}`) }
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.creditNote.processingErrorMessage` }>
                                { creditNote.processingErrorMessage }
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.creditNote.documentDate` }>{ formatDate(creditNote.documentDate) }</TableCell>
                        </TableRow>
                    )) }
                    { fetchState === 'SUCCESS' && !sortedCreditNotesWithProcessingError.length && (
                        <TableRow
                            data-test={ `${dataTest}.table.content.empty` }>
                            <TableCell
                                colSpan={ 5 }
                                data-test={ `${dataTest}.table.content.empty.label` }
                                align="center">
                                { message('creditNotesWithProcessingErrorTable.noErrors') }
                            </TableCell>
                        </TableRow>
                    ) }
                    { (fetchState === 'INIT' || fetchState === 'FETCHING') && [ ...Array(5).keys() ].map(index => (
                        <TableRow
                            key={ index }
                            data-test={ `${dataTest}.table.loading` }>
                            <TableCell data-test={ `${dataTest}.table.loading.creditNoteNumber` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.recipient` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.processingErrorCode` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.processingErrorMessage` }>
                                <Skeleton />
                            </TableCell>
                            <TableCell data-test={ `${dataTest}.table.loading.documentDate` }>
                                <Skeleton />
                            </TableCell>
                        </TableRow>
                    )) }
                </TableBody>

            </Table>
        </Paper>
    );
};
