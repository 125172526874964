import { useRef } from 'react';

import { CreatorAggregations } from '~api/model/dashboardAggregations';

import { CreatorLevel } from '~constants/creatorLevel';

import { formatNumber, message } from '~helper/_common';

import { useShowDetailsTile } from '~hooks/useShowDetailsTile';

import { LevelBadge } from '~components/atoms/LevelBadge';
import { LabeledElement } from '~components/molecules/LabeledElement';
import { CreatorsDetailsTile } from '~components/organism/CreatorsDetailsTile';
import { DataTile } from '~components/organism/DataTile';
import { DetailsTile } from '~components/organism/DetailsTile';
import { ComponentCommonProps } from '~components/types';

export type CreatorsDataTileProps = ComponentCommonProps & {
    loading: boolean;
    data: CreatorAggregations['creators'] | null;
}

export const CreatorsDataTile: React.FC<CreatorsDataTileProps> = ({
    className,
    data,
    loading,
    'data-test': dataTest = 'creatorsDataTile',
}) => {
    const dataTileRef = useRef(null);
    const { showDetailsTile, setShowDetailsTile } = useShowDetailsTile();

    return (
        <>
            { showDetailsTile && (
                <DetailsTile
                    dataTileRef={ dataTileRef }
                    onClose={ () => setShowDetailsTile(false) }
                    data-test={ `${dataTest}.details` }>
                    <CreatorsDetailsTile data={ data } data-test={ `${dataTest}.details.creator` } />
                </DetailsTile>
            ) }
            <DataTile
                className={ className }
                contentType="creator"
                headline={ message('dataTile.creator.headline') }
                headlineSecondary={ message('common.total') }
                mainDataValue={ formatNumber(data?.total || 0) }
                headlineVariant="h4"
                subHeadlineVariant="h5"
                loading={ loading }
                data-test={ dataTest }
                ref={ dataTileRef }
                onExpandClick={ () => setShowDetailsTile(true) }
                chartValues={ data?.history?.map(historyEntry => ({ date: historyEntry.date, value: historyEntry.total })) }
                chartValueLabel={ message('dataTile.details.creator.chart.tooltipLabel') }>
                <LabeledElement
                    label={ message('creator.level.MEMBER') }
                    labelIcon={ <LevelBadge level={ CreatorLevel.MEMBER } /> }
                    loading={ loading }
                    data-test={ `${dataTest}.subDataValues.member` }>
                    { formatNumber(data?.MEMBER || 0) }
                </LabeledElement>
                <LabeledElement
                    label={ message('creator.level.SILVER') }
                    labelIcon={ <LevelBadge level={ CreatorLevel.SILVER } /> }
                    loading={ loading }
                    data-test={ `${dataTest}.subDataValues.silver` }>
                    { formatNumber(data?.SILVER || 0) }
                </LabeledElement>
                <LabeledElement
                    label={ message('creator.level.GOLD') }
                    labelIcon={ <LevelBadge level={ CreatorLevel.GOLD } /> }
                    loading={ loading }
                    data-test={ `${dataTest}.subDataValues.gold` }>
                    { formatNumber(data?.GOLD || 0) }
                </LabeledElement>
                <LabeledElement
                    label={ message('creator.level.PLATINUM') }
                    labelIcon={ <LevelBadge level={ CreatorLevel.PLATINUM } /> }
                    loading={ loading }
                    data-test={ `${dataTest}.subDataValues.platinum` }>
                    { formatNumber(data?.PLATINUM || 0) }
                </LabeledElement>
            </DataTile>
        </>
    );
};
