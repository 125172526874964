import { useDispatch } from 'react-redux';
import { Alert } from '@mui/material';

import { message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { fetchDashboardAggregations } from '~modules/dashboardAggregations';
import { useDashboardAggregations } from '~modules/dashboardAggregations/selectors';

import { BillableViewsDataTile } from '~components/organism/BillableViewsDataTile';
import { CollectedBeautyPointsDataTile } from '~components/organism/CollectedBeautyPointsDataTile';
import { CollectedCashDataTile } from '~components/organism/CollectedCashDataTile';
import { CompensableViewsDataTile } from '~components/organism/CompensableViewsDataTile';
import { CreatorsDataTile } from '~components/organism/CreatorsDataTile';
import { PaidOutBeautyPointsDataTile } from '~components/organism/PaidOutBeautyPointsDataTile';
import { PaidOutCashDataTile } from '~components/organism/PaidOutCashDataTile';
import { VideosDataTile } from '~components/organism/VideosDataTile';
import { RequireFeature } from '~components/utils/RequireFeature';

import style from './DashboardAggregations.module.scss';

export const DashboardAggregations: React.FC = () => {
    const { data: dashboardAggregations, fetchState } = useDashboardAggregations();

    const loading = fetchState === 'FETCHING';

    const dispatcher = useDispatch();

    useInitialLoad(() => {
        dispatcher(fetchDashboardAggregations());
    });

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert severity="warning" data-test="dashboardAggregations.forbiddenNotification">
                { message('api.forbidden') }
            </Alert>
        );
    }

    if (fetchState === 'ERROR') {
        return (
            <Alert severity="error" data-test="dashboardAggregations.errorNotification">
                { message('api.error') }
            </Alert>);
    }

    return (
        <div className={ style.grid } data-test="dashboardAggregations">
            <CreatorsDataTile
                loading={ loading }
                data={ dashboardAggregations?.creators || null }
                data-test="dashboardAggregations.creator" />
            <VideosDataTile
                hasDetailsTile
                loading={ loading }
                data={ dashboardAggregations?.videos || null }
                data-test="dashboardAggregations.videos" />
            <BillableViewsDataTile
                hasDetailsTile
                loading={ loading }
                data={ dashboardAggregations?.billableViews || null }
                data-test="dashboardAggregations.billableViews" />
            <CompensableViewsDataTile
                hasDetailsTile
                loading={ loading }
                data={ dashboardAggregations?.compensableViews || null }
                data-test="dashboardAggregations.compensableViews" />
            <CollectedBeautyPointsDataTile
                hasDetailsTile
                loading={ loading }
                data={ dashboardAggregations?.collectedBeautyPoints || null }
                data-test="dashboardAggregations.collectedBeautyPoints" />
            <RequireFeature feature="SCD-1029-payout-with-cash">
                <CollectedCashDataTile
                    hasDetailsTile
                    loading={ loading }
                    data={ dashboardAggregations?.collectedCash || null }
                    data-test="dashboardAggregations.collectedCash" />
            </RequireFeature>
            <PaidOutBeautyPointsDataTile
                hasDetailsTile
                loading={ loading }
                data={ dashboardAggregations?.paidOutBeautyPoints || null }
                data-test="dashboardAggregations.paidOutBeautyPoints" />
            <RequireFeature feature="SCD-1029-payout-with-cash">
                <PaidOutCashDataTile
                    hasDetailsTile
                    loading={ loading }
                    data={ dashboardAggregations?.paidOutCash || null }
                    data-test="dashboardAggregations.paidOutCash" />
            </RequireFeature>
        </div>
    );
};
